const app = new Vue({
  i18n,
  el: '#app',
  data: {
    book_a_showing: false,
    user_info: {
      first_name: "",
      last_name: "",
      phone_number: "",
      email: ""
    }
  },
  methods: {
    closeBookAShowing() {
      this.book_a_showing = false;
    },
    openBookAShowing() {
      this.book_a_showing = true;
    },
    onLeave() {
      if (this.$refs.exitIntent) {
        this.$refs.exitIntent.is_open = true;
      }
    },
      // showRegisterForm() {
      //     return this.showForm();
      // },
      // showRegisterFormWithTemplate(template_id) {
      //     return this.showForm(template_id);
      // },
      // showForm(template_id = null) {
      //     if (!this.is_logged_in) {
      //         this.form = true;
      //         this.template_id = template_id;
      //     } else {
      //         window.location.href = this.app_url;
      //     }
      // }
  },
  mounted() {
    console.log('mounted');
  }
});